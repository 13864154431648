import SectionTitle from "./SectionTitle";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CategoryCard = () => {
  const { items: data } = useSelector((state) => state.category);

  return (
    <div className="wrapper mt-10">
      <div className="grid lg:grid-cols-4  grid-cols-2  gap-3 md:gap-10">
        {data.slice(0, 8).map((data) => (
          <Link
            to={`/categorypage/${data.id}`}
            key={data.id}
            className="rounded-lg overflow-hidden"
          >
            <div className="h-auto md:h-auto md lg:h-[18rem] 2xl:h-[21.8rem] overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/uploads/category/${data?.icon}`}
                alt=""
                className="w-full h-full object-fill"
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CategoryCard;
