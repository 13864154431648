import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubCatCard = ({ subCategory }) => {
  return (
    <div className="grid grid-cols-3 lg:grid-cols-4 gap-5">
      {subCategory?.subcategory?.map((sub) => (
        <Link
          to={`/subcategory/${sub.id}`}
          key={sub.id}
          className="rounded-lg overflow-hidden"
        >
          <div className="h-[5rem]  md:h-[10rem] lg:h-[18rem] 2xl:h-[21.8rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/subcategory/${sub?.icon}`}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex items-center justify-center">
            <span className="text-lg lg:text-xl font-semibold text-[#2AAAE0]">
              {sub.name}
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SubCatCard;
